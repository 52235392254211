import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/app-config.service';
import { StorageService } from 'src/app/common/storage.service';
import Swal from 'sweetalert2';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {

  labelPosition: 'before' | 'after' = 'after';

  form: FormGroup;

  activeLang = this.sessionSrv.getLanguage();

  constructor(
    public configSrv: AppConfigService,
    private fb: FormBuilder,
    private loginSrv: LoginService,
    private router: Router,
    private translate: TranslateService,
    private sessionSrv: StorageService,
  ) {
    this.translate.setDefaultLang(this.activeLang);

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
  }

  sendWithEmail(){
    this.loginSrv.restorePassword(this.form.value).subscribe(resp => {
      if (resp.status) {
        Swal.fire({
          title: '<strong>Contraseña cambiada con exito!</strong>',
          icon: 'success',
          html:
          `<b>Revisa la bandeja de tu correo, para ver los datos de inicio de sesion.</b>`
        });

        this.router.navigate(['../login']);
      } else {
        Swal.fire({
          title: `<strong>${resp.message}</strong>`,
          icon: 'error'
        });
      }
    });
  }


}
