import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Rules } from 'src/app/common/rules';
import { StorageService } from 'src/app/common/storage.service';
import Swal from 'sweetalert2';
import { Cart } from '../../models/cart';
import { Client } from '../../models/client';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  public form: FormGroup;

  hide = true;
  hide2 = true;

  client!: Client;
  cart!: Cart;

  strongPwdRgx = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  rules = this.rulesSrv.getChangePasswordRules();

  activeLang = this.sessionSrv.getLanguage();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private loginSrv: LoginService,
    private rulesSrv: Rules,
    private sessionSrv: StorageService,
    private translate: TranslateService
  ) { 
    this.client = this.sessionSrv.getCurrentSession();

    this.translate.setDefaultLang(this.activeLang);

    this.form = this.fb.group({
      oldPassword: ['', [Validators.required, Validators.minLength(5), Validators.pattern(this.strongPwdRgx)]],
      password: ['', [Validators.required, Validators.minLength(5), Validators.pattern(this.strongPwdRgx)]],
      email: [this.client.email, [Validators.required, Validators.email]],
    });

    if (!this.client || this.client === undefined) {
      this.router.navigate(['../']);
    }
  }

  ngOnInit(): void {
  }

  changePassword() {
    const data = JSON.parse(JSON.stringify(this.form.value));
    data.oldPassword = btoa(data.oldPassword.trim());
    data.password = btoa(data.password.trim());
    // data.updated_by = 1;

    this.loginSrv.changePassword(data).subscribe(resp => {
      if (resp.status) {
        Swal.fire('¡Proceso exitoso!', `${resp.message}`, 'success');
        this.router.navigate(['../']);
      } else {
        Swal.fire('¡Algo a fallado!', `${resp.message}`, 'error');
      }
    });
  }

}
