 <!-- Hero Start -->
 <section style="align-items: center; padding: 150px 0;">
    <div class="container">
        <div class="row" style="justify-content: center;">
            <div class="col-lg-6 col-md-8"> 
                <table style="box-sizing: border-box; width: 100%; border-radius: 6px; overflow: hidden; background-color: #fff; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">
                    <thead>
                        <tr style="background-color: #7952B3; padding: 3px 0; line-height: 68px; text-align: center; color: #fff; font-size: 24px; font-weight: 700; letter-spacing: 1px;">
                            <th scope="col"><img src="assets/images/logo-khan-light.png" height="24" alt=""></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td style="padding: 48px 24px 0; color: #161c2d; font-size: 18px; font-weight: 600;">
                                Estimado usuario.
                            </td>
                        </tr>
                        <tr>
                            <td style="padding: 15px 24px 15px; color: #8492a6;">
                                Gracias por registrarte en este sitio web. Para continuar, confirme su dirección de correo electrónico haciendo clic en el botón de abajo.
                            </td>
                        </tr>

                        <tr>
                            <td style="padding: 15px 24px;">
                                <a (click)="emailConfirmation()" class="btn-primary" style="padding: 8px 20px; outline: none; text-decoration: none; font-size: 16px; letter-spacing: 0.5px; transition: all 0.3s; font-weight: 600; border-radius: 6px; cursor: pointer;">Confirmar el correo electrónico.</a>
                            </td>
                        </tr>

                        <tr>
                            <td style="padding: 15px 24px 0; color: #8492a6;">
                                Este enlace estará activo durante 30 minutos desde el momento en que se envió este correo electrónico.
                            </td>
                        </tr>

                        <tr>
                            <td style="padding: 15px 24px 15px; color: #8492a6;">
                                KAN <br>
                            </td>
                        </tr>

                        <tr>
                            <td style="padding: 16px 8px; color: #8492a6; background-color: #f8f9fc; text-align: center;">
                                © 2020-21 KAN.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>
