import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './public-pages/auth/change-password/change-password.component';
import { LoginComponent } from './public-pages/auth/login/login.component';
import { RecoverPasswordComponent } from './public-pages/auth/recover-password/recover-password.component';
import { RegisterComponent } from './public-pages/auth/register/register.component';
import { ValidateEmailComponent } from './public-pages/auth/validate-email/validate-email.component';
import { ValidatePostulationComponent } from './public-pages/auth/validate-postulation/validate-postulation.component';

import { MainComponent } from './public-pages/main/main.component';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    loadChildren: () => import('./public-pages/public-pages.module').then(m => m.PublicPagesModule),
    data: {
        title: 'Kan',
        ogTitle: 'Kan Data Market',
        ogDescription: 'Banco de datos de estadísticas, resultados de informes y estudios sectoriales de todo el país, realizado por expertos.',
        ogImage: 'https://ws.kandataexperts.com/files/image/2021/Aug/Fri/611ff86883b0c.jpg'
    }
  },
    // RUTAS PARA AUTH
    {
      path: 'login',
      component: LoginComponent,
      data: {
        title: 'Inicio de Sesión',
        ogTitle: 'Inicio de sesion',
        ogDescription: 'Inicio de sesión',
        ogImage: 'https://ws.kandataexperts.com/files/image/2021/Aug/Fri/611ff86883b0c.jpg'
      }
    },
    {
      path: 'register',
      component: RegisterComponent,
      data: {
        title: 'Creación de Cuenta',
        ogTitle: 'Creación de cuenta',
        ogDescription: 'Creación de cuenta',
        ogImage: 'https://ws.kandataexperts.com/files/image/2021/Aug/Fri/611ff86883b0c.jpg'
      }
    },
    {
      path: 'recover-password',
      component: RecoverPasswordComponent,
      data: {
        title: 'Recuperación de Contraseña'
      }
    },
    {
      path: 'validate-email',
      component: ValidateEmailComponent,
      data: {
        title: 'Validacion de Cuenta'
      }
    },
    {
      path: 'change-password',
      component: ChangePasswordComponent,
      data: {
        title: 'Cambio de Contraseña'
      }
    },
    {
      path: 'postulation/validation',
      component: ValidatePostulationComponent,
      data: {
        title: 'Validación de Postulación'
      }
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
