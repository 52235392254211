import { Pipe, PipeTransform } from '@angular/core';
import { AppConfigService } from '../../app-config.service';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  constructor(
    private appConfig: AppConfigService,
  ) { }

  transform(img: string): string {
    if ( !img ) {
      return './assets/images/sin_imagen.png';
    }
    return `${ this.appConfig.getUrlBaseFiles() }${ img }`;
  }

}
