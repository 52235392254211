<!-- Footer Start -->
<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                <a href="#" class="logo-footer">
                    <img [alt]="company.name" [src]="company.logo" height="48" [routerLink]="['/']">
                    <p style="color: white; font-size: medium;" class="mt-2">{{ 'footer.company.description' | translate }}</p>
                </a>
            </div>

            <div class="col-lg-3 col-md-3 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h4 class="text-light footer-head">{{ 'footer.first_menu.title' | translate }}</h4>
                <ul class="list-unstyled footer-list mt-4">
                    <li><a routerLink="/" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> {{
                            'footer.first_menu.first_item' | translate }}</a>
                    </li>
                    <li><a routerLink="/about" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> {{
                            'footer.first_menu.second_item' | translate }}</a>
                    </li>
                    <li><a [routerLink]="['/topics']" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>
                            {{ 'footer.first_menu.third_item' | translate }}</a>
                    </li>
                    <li><a [routerLink]="['/news/8']" class="text-foot"><i
                                class="mdi mdi-chevron-right mr-1"></i> {{ 'footer.first_menu.fourth_item' | translate
                            }}</a>
                    </li>
                    <li><a [routerLink]="['/news/9']" class="text-foot"><i
                                class="mdi mdi-chevron-right mr-1"></i> {{ 'footer.first_menu.fifth_item' | translate
                            }}</a>
                    </li>
                    <li><a routerLink="/data-science" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>
                            {{ 'footer.first_menu.sixth_item' | translate }}</a>
                    </li>
                    <li><a routerLink="/news" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>
                            {{ 'footer.first_menu.seventh_item' | translate }}</a>
                    </li>
                </ul>
            </div>

            <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h4 class="text-light footer-head">{{ 'footer.second_menu.title' | translate }}</h4>
                <ul class="list-unstyled footer-list mt-4">
                    <li *ngFor="let item of links"><a [href]="item.reference" target="_blank" class="text-foot"><i
                                class="mdi mdi-chevron-right mr-1"></i> {{item.title}}</a> </li>
                    <li><a [routerLink]="['/news/politicas_de_privacidad/14']" class="text-foot"><i
                                class="mdi mdi-chevron-right mr-1"></i>
                            {{ 'footer.second_menu.first_item' | translate }}</a>
                    </li>
                </ul>
            </div>

            <div class="col-lg-2 col-md-3 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h4 class="text-light footer-head">{{ 'footer.third_menu.title' | translate }}</h4>
                <ul class="list-unstyled footer-list mt-4">
                    <li><a routerLink="/news/3" class="text-foot"><i
                                class="mdi mdi-chevron-right mr-1"></i>
                            {{ 'footer.third_menu.first_item' | translate }}</a>
                    </li>
                    <li><a routerLink="/postulation" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>
                            {{ 'footer.third_menu.second_item' | translate }}</a>
                    </li>



                    <!-- <li><a class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>
                            Documentación</a>
                    </li> -->

                </ul>
            </div>

            <div class="col-lg-2 col-md-3 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h4 class="text-light footer-head">{{ 'footer.fourth_menu.title' | translate }}</h4>
                <ul class="list-unstyled footer-list mt-4">
                    <li><a routerLink="/contact" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> {{ 'footer.fourth_menu.first_item' | translate }} </a>
                    </li>
                </ul>
            </div>

            <div class="col-sm-12">
                <ul class="list-unstyled social-icon social mb-0 ml-1 text-sm-right">
                    <li *ngFor="let item of socials" class="list-inline-item ml-1">
                        <a [href]="item.reference" target="_blank" class="rounded">
                            <i-feather [name]="item.icon" class="fea icon-sm fea-social"></i-feather>
                        </a>
                    </li>
                </ul>
            </div>

        </div>

    </div>

</footer>

<footer class="footer footer-bar">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="text-sm-left">
                    <p class="mb-0">Power by Cardinal / <a [href]="company.developer_href" target="_blank"
                            class="text-muted">Limberit.</a> </p>
                </div>
            </div>

            <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <ul class="list-unstyled text-sm-right mb-0">
                    <li class="list-inline-item ml-1">
                        <a href="javascript:void(0)"><img src="assets/images/payments/master-card.png"
                                class="avatar avatar-ex-sm" title="Master Card" alt=""></a>
                    </li>
                    <li class="list-inline-item ml-1">
                        <a href="javascript:void(0)"><img src="assets/images/payments/visa.png"
                                class="avatar avatar-ex-sm" title="Visa" alt=""></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>

<app-tap-to-top></app-tap-to-top>