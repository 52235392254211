import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config.service';
import { HandleError } from 'src/app/common/handle-error';

@Injectable({
  providedIn: 'root'
})
export class TopicService {

  constructor(
    private configSrv: AppConfigService,
    private http: HttpClient,
    private handleError: HandleError
  ) { }

  getTopics(query?: any) {
    let options: any = {};
    options.headers = { skip: "true" };

    if (query) {
      options.params = new HttpParams()
      .set('page', query.pageIndex.toString())
      .set('pageSize', query.pageSize.toString())
      .set('sortActive', query.sortActive)
      .set('sortDirection', query.sortDirection)
      .set('filter', query.filter);
    }

    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'topic', options ).pipe(
      catchError(this.handleError.handleError<any>('getTopics'))
    );
  }

  public getTopic(topicCode: string) {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'topic/code/' + topicCode, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getTopic`))
    );
  }
}
