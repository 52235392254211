
import  {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { CartService } from '../public-pages/services/cart.service';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private storageService: StorageService,
    private cartSrv: CartService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
    req=req.clone();
    
    let token;

    if (!req.headers.get("skip")) {
      if(this.storageService.isAuthenticated()) {
        token = this.storageService.getCurrentToken();
      }else if(req.urlWithParams === "/assets/i18n/es.json" || req.urlWithParams === "/assets/i18n/en.json"){
      }else{
        this.router.navigate(['/login']);
        this.cartSrv.resetCart();
      }
    }

    let headers;
    
    if (req.headers.get("skipContent") === "true") {
      headers = new HttpHeaders({
        "Accept": "application/json",
        
      });
    } else {
      if(token) {
        headers = new HttpHeaders({
          'Content-Type':'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`
        });
      } else {
        headers = new HttpHeaders({
          'Content-Type':'application/json; charset=utf-8',
        });
      }
    }

    req=req.clone({headers});

      return next.handle(req).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //todo bien
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.storageService.logout();
            this.cartSrv.resetCart();
            this.router.navigate(['/login']);

          }else if (err.status === 400) {
            //this.authService.logOut();
            this.storageService.logout();
            this.cartSrv.resetCart();
            this.router.navigate(['/login']);
          }
        }
      });
  }
}
