import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Article } from '../models/article';
import { AppConfigService } from 'src/app/app-config.service';
import { HandleError } from 'src/app/common/handle-error';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private handleError: HandleError
  ) { }

  public getArticle( articleId: number ) {
    return this.http.get<any>( this.appConfig.getUrlBasePublic() + 'article/' + articleId, { headers: { skip: "true" } }   ).pipe(
      catchError( this.handleError.handleError<any>(`getArticle`))
    );
  }

  public getArticles( articleTypeId: number, limit: number ) {
    return this.http.get<any>( this.appConfig.getUrlBasePublic() + 'article/articleType/' + articleTypeId + '/limit/' + limit, { headers: { skip: "true" } }   ).pipe(
      catchError( this.handleError.handleError<any>(`getArticles`))
    );
  }

  public getArticleRandom( articleTypeId: number ) {
    return this.http.get<any>( this.appConfig.getUrlBasePublic() + 'article/articleType/' + articleTypeId + '/random', { headers: { skip: "true" } }  ).pipe(
      catchError( this.handleError.handleError<any>(`getArticleRandom`))
    );
  }

  public getArticleFeatured( articleTypeId: number, limit = 0 ) {
    return this.http.get<any>( this.appConfig.getUrlBasePublic() + 'article/articled/articleType/' + articleTypeId + '/limit/' + limit, { headers: { skip: "true" } } ).pipe(
      catchError( this.handleError.handleError<any>(`getFeatureFeatured`))
    )
  }
}
