import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/app-config.service';
import { Article } from 'src/app/public-pages/models/article';
import { ArticleService } from 'src/app/public-pages/services/article.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  links: Article[] = [];
  socials: Article[] = [];
  company = this.configSrv.company.data;

  constructor(
    private articleSrv: ArticleService,
    private configSrv: AppConfigService
  ) { }

  ngOnInit(): void {
    this.getLinks();
    this.getSocials();
  }

  getLinks() {
    this.articleSrv.getArticles(this.configSrv.articles.links_footer.article_type, this.configSrv.articles.links_footer.limit).subscribe(
      resp => {
        if (resp.status) {
          this.links = resp.data;
        }
      }
    );
  }

  getSocials() {
    this.articleSrv.getArticles(this.configSrv.articles.socials.article_type, this.configSrv.articles.socials.limit).subscribe(
      resp => {
        if (resp.status) {
          this.socials = resp.data;
        }
      }
    );
  }

}
