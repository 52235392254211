
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor() { }
  private urlBasePrivate = environment.urlBasePrivate;
  private urlBasePublic = environment.urlBasePublic;
  private urlBaseSecurity = environment.urlBaseSecurity;
  private urlBaseFiles = environment.urlBaseFiles;
  private urlWa = environment.urlWa;
  private urlSAT = environment.urlSAT;
  private urlVisaFingerprint = environment.urlVisaFingerprint;
  private urlIpInfo = environment.urlIpInfo;
  private ipInfoToken = environment.ipInfoToken;

  company = environment.company;

  newsNewsLimit = environment.newsNewsLimit;

  articles = {
    banner_home: {
      article_type: 2,
      limit: 0
    },
    standars: {
      article_type: 8,
      limit: 5
    },

    history: {
      article_type: 5,
      limit: 0
    },
    values: {
      article_type: 7,
      limit: 6
    },
    makes: {
      article_type: 14,
      limit: 3
    },
    banners_data_science: {
      article_type: 10,
      limit: 0
    },
    consultancies: {
      article_type: 12,
      limit: 5
    },
    managements: {
      article_type: 13,
      limit: 5
    },
    works: {
      article_type: 6,
      limit: 3
    },
    contact: {
      article_type: 9,
      limit: 0
    },
    socials: {
      article_type: 3,
      limit: 6
    },
    links_footer: {
      article_type: 11,
      limit: 5
    },

  }

  articles_titles = {
    standar: {
      article: 40,
    },
    values: {
      article: 44,
    },
    consultancy: {
      article: 41,
    },
    management: {
      article: 42,
    },
    price_detail: {
      article: 48,
    },
    image_works: {
      article: 49,
    },
    welcome_message: {
      article: 50,
    },
    terms_postulation: {
      article: 52,
    },
    terms_products: {
      article: 53,
    },
    subscribe: {
      article: 54,
    },
  }

  news = {
    news_data: {
      limit: 0
    },
  }

  makes = {
    make_one: {
      article_id: 39
    },
    make_two: {
      article_id: 37
    },
    make_three: {
      article_id: 38
    }
  }

  getUrlBasePublic() {
    return this.urlBasePublic;
  }

  getUrlBasePrivate() {
    return this.urlBasePrivate;
  }

  getUrlBaseSecurity() {
    return this.urlBaseSecurity;
  }

  getUrlBaseFiles() {
    return this.urlBaseFiles;
  }

  getUrlIpInfo() {
    return this.urlIpInfo;
  }

  getIpInfoToken() {
    return this.ipInfoToken;
  }

  getUrlWa() {
    return this.urlWa;
  }

  getUrlSAT() {
    return this.urlSAT;
  }

  getUrlVisaFingerprint() {
    return this.urlVisaFingerprint;
  }

}
