import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProviderPostulationService } from '../../services/provider-postulation.service';

@Component({
  selector: 'app-validate-postulation',
  templateUrl: './validate-postulation.component.html',
  styleUrls: ['./validate-postulation.component.css']
})
export class ValidatePostulationComponent implements OnInit {

  message = 'Es necesario registrar sus datos de postulación';

  constructor(private router: Router,
              private postulationSrv: ProviderPostulationService) { }

  ngOnInit(): void {
    if(this.postulationSrv.getPostulationResult){
      this.message = `Gracias por solicitar su postulación como proveedor. Revise su buzón de correo electrónico.`
    }else{
      this.router.navigate(['/postulation']);
    }
  }

  back() {
    this.router.navigate(['../../']);
  }

}
