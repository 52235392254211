import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/app/common/storage.service';
import { TraductorService } from '../services/traductor.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  addclass: string;
  activeLang = this.sessionSrv.getLanguage();

  constructor(
    private translate: TranslateService,
    private traductorSrv: TraductorService,
    private sessionSrv: StorageService
  ) {
    if (!this.activeLang) {
      this.sessionSrv.setLanguage('es');
    }
  }

  ngOnInit(): void {
    // this.translate.setDefaultLang(this.activeLang);
  }

  // cambiarLenguaje(lang: any) {
  //   this.activeLang = lang;
  //   this.translate.use(lang);
  // }

  onActivate(componentReference: any) {
    this.addclass = componentReference.navClass;
  }


}
