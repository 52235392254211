export const environment = {
  production: true,
  urlBasePrivate: 'https://ws.kandataexperts.com/api_sigma/public/index.php/api/',
  urlBasePublic: 'https://ws.kandataexperts.com/api_sigma/public/index.php/api-public/',
  urlBaseSecurity: 'https://ws.kandataexperts.com/api_security/public/index.php/api/',
  urlBaseFiles: 'https://ws.kandataexperts.com/files/',
  urlWa: 'https://api.whatsapp.com/send?phone=' + '50243615316' + '&text=',
  urlSAT: 'https://felav02.c.sat.gob.gt/fel-rest/rest/publico/receptor/',
  urlVisaFingerprint: 'https://h.online-metrix.net/fp/',
  urlIpInfo: 'https://ipinfo.io/',
  ipInfoToken: '2d4c017ff18e10',

  company: {
    data: {
      name: 'KAN DATA EXPERTS',
      short_name: 'KAN',
      developer: 'LimberIT',
      developer_href: 'https://www.limberit.com/#/',
      description: 'Proveedores de información precisa y exacta.',
      slogan: 'Información precisa y exacta.',
      logo: 'assets/images/KAN_GRIS.png',
      address: 'Ciudad de Guatemala, C.A.',
      country_code: '502',
      phone: '43615316',
      movil: '43615316',
      email: 'production@mail.com',
      currency: 'USD',
      org_id: 'k8vif92e',
      merchant_id: 'visanetgt_cardinal',
      is_development: 'production',
      urlWebsite: 'https://kandataexperts.com/'
    }
  },

  newsNewsLimit: 15
};
