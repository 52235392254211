import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config.service';
import { HandleError } from 'src/app/common/handle-error';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private http: HttpClient,
    private configSrv: AppConfigService,
    private handleError: HandleError,
  ) { }

  getClient(clientId: number) {
    return this.http.get<any>(this.configSrv.getUrlBasePrivate() + 'client/' + clientId).pipe(
      catchError(this.handleError.handleError<any>('getClient'))
    );
  }

  setClient(data: any) {
    return this.http.post<any>(this.configSrv.getUrlBasePublic() + 'client', data, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>('setClient'))
    );
  }

  putClient(clientId: number, data: any) {
    return this.http.put<any>(this.configSrv.getUrlBasePrivate() + 'client/' + clientId, data).pipe(
      catchError(this.handleError.handleError<any>('putClient'))
    );
  }

  getProductsPurchase(code: any) {
    return this.http.get<any>(this.configSrv.getUrlBasePrivate() + 'clientProduct/client/' + code + '/products').pipe(
      catchError(this.handleError.handleError<any>('getProductsPurchase'))
    );
  }

  // emailConfirmation(client?: any) {
  //   let options = {};
  //   if (client) {
  //     options = {
  //       params: new HttpParams()
  //         .set('code', client.code)
  //         .set('email', client.email)
  //         .set('name', client.name)
  //     };
  //   }
  //   return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'confirmEmail', options).pipe(
  //     catchError(this.handleError.handleError<any>('emailConfirmation'))
  //   );
  // }

  emailConfirmation(data: any) {
    return this.http.put<any>(this.configSrv.getUrlBasePublic() + 'client/confirmEmail', data, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>('emailConfirmation'))
    );
  }

  public getClientsFeatured(limit: number) {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'client/featured/limit/' + limit, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getClientsFeatured`))
    );
  }
}
