<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass">
    <div class="container">
        <div (click)="closeMenu()">
            <a class="logo" *ngIf="navClass !== 'nav-light'" [routerLink]="['/']" aria-label="kan">
                <img src="assets/images/KAN_OSCURO.png" alt="Kan Data Experts" class="l-dark" height="36" alt="">
                <img src="assets/images/KAN_OSCURO.png" alt="Kan Data Experts" class="l-light" height="36" alt="">
            </a>
            <a class="logo" *ngIf="navClass === 'nav-light'" [routerLink]="['/']" aria-label="kan">
                <img src="assets/images/KAN_OSCURO.png" alt="Kan Data Experts" class="l-dark" height="36" alt="">
                <img src="assets/images/KAN_BLANCO.png" alt="Kan Data Experts" class="l-light" height="36" alt="">
            </a>
        </div>

        <div class="buy-button" *ngIf="!this.session && options === true">
            <a class="logo" style="cursor: pointer;" [routerLink]="['login']" *ngIf="navClass !== 'nav-light'">
                <img src="assets/images/icon/log-in-azul.png" class="l-dark" height="36" alt="Iniciar Sesión"
                    title="Iniciar Sesión">
                <img src="assets/images/icon/log-in-azul.png" class="l-light" height="36" alt="Iniciar Sesión"
                    title="Iniciar Sesión">
            </a>
            <a class="logo" style="cursor: pointer;" [routerLink]="['login']" *ngIf="navClass === 'nav-light'">
                <img src="assets/images/icon/log-in-azul.png" class="l-dark" height="36" alt="Iniciar Sesión"
                    title="Iniciar Sesión">
                <img src="assets/images/icon/log-in-blanco.png" class="l-light" height="36" alt="Iniciar Sesión"
                    title="Iniciar Sesión">
            </a>
        </div>

        <div class="buy-button" *ngIf="this.session && options === true">
            <a class="logo" style="cursor: pointer;" (click)="logout()" *ngIf="navClass !== 'nav-light'">
                <img src="assets/images/icon/log-out-azul.png" class="l-dark" height="24" title="Cerrar Sesión">
                <img src="assets/images/icon/log-out-azul.png" class="l-light" height="24" title="Cerrar Sesión">
            </a>
            <a class="logo" style="cursor: pointer;" (click)="logout()" *ngIf="navClass === 'nav-light'">
                <img src="assets/images/icon/log-out-azul.png" class="l-dark" height="24" title="Cerrar Sesión">
                <img src="assets/images/icon/log-out-blanco.png" class="l-light" height="24" title="Cerrar Sesión">
            </a>
        </div>
        
        <div class="buy-button" *ngIf="options === true">
            <a class="logo" [routerLink]="['cart']" style="cursor: pointer;" *ngIf="navClass !== 'nav-light'">
                <span class="badge badge-pill badge-light float-right" style="margin-top: 5px !important">{{
                    shoppingCartItems.length }}</span>
                <img src="assets/images/icon/cart-azul.svg" alt="carrito de compras" class="l-dark" height="24">
                <img src="assets/images/icon/cart-azul.svg" alt="carrito de compras" class="l-light" height="24">
            </a>
            <a class="logo" [routerLink]="['cart']" style="cursor: pointer;" *ngIf="navClass === 'nav-light'">
                <span class="badge badge-pill badge-light float-right" style="margin-top: 5px !important">{{
                    shoppingCartItems.length }}</span>
                <img src="assets/images/icon/cart-azul.svg" alt="carrito de compras" class="l-dark" height="24">
                <img src="assets/images/icon/cart-blanco.svg" alt="carrito de compras" class="l-light" height="24">
            </a>
        </div>

        <div class="menu-extras">
            <div class="menu-item">
                <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
            </div>
        </div>

        <div id="navigation">
            <ul class="navigation-menu" [class]="navClass">
                <!-- PRIMER MENU -->
                <!-- INICIO -->
                <li><a routerLink="/" (click)="closeMenu()" class="nav-link-ref">{{ 'header.first_menu' | translate }}</a></li>
                <!-- SEGUNDO MENU -->
                <!-- NOSOTROS -->
                <li><a routerLink="/about" (click)="closeMenu()" class="nav-link-ref">{{ 'header.second_menu' | translate }}</a></li>
                <!-- TERCER MENU -->
                <!-- PRODUCTOS Y LICENCIAS -->
                <li class="has-submenu">
                    <a href="javascript:void(0)" (click)="onMenuClick($event)">{{ 'header.third_menu.title' | translate }}</a><span class="menu-arrow"></span>
                    <ul class="submenu">
                        <!-- POR PAISES -->
                        <li class="has-submenu">
                            <a href="javascript:void(0)" (click)="onMenuClick($event)"> {{'header.third_menu.first_item' | translate }}</a><span class="submenu-arrow"></span>
                            <ul class="submenu">
                                <li *ngFor="let item of countries">
                                    <a class="nav-link-ref"
                                        [routerLink]="['/products/country', item.country, item.country_id]" (click)="closeMenu()"> {{ item | traductor: 'country' }} </a>
                                </li>
                            </ul>
                        </li>
                        <!-- POR INDUSTRIAS -->
                        <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> {{'header.third_menu.second_item' | translate }}
                            </a><span class="submenu-arrow"></span>
                            <ul class="submenu">
                                <li *ngFor="let item of industries">
                                    <a class="nav-link-ref" [routerLink]="['/products/industry', item.industry_url, item.industry_id]" (click)="closeMenu()"> {{ item | traductor: 'industry' }} </a>
                                </li>
                            </ul>
                        </li>
                        <!-- TEMATICAS -->
                        <li><a class="nav-link-ref" [routerLink]="['/topics']" (click)="closeMenu()">{{'header.third_menu.third_item' | translate }}</a></li>
                        <!-- LICENCIAS -->
                        <li><a class="nav-link-ref" [routerLink]="['/news/8']" (click)="closeMenu()">{{'header.third_menu.fourth_item' |translate }}</a></li>
                        <!-- PREGUNTAS FRECUENTES -->
                        <li><a class="nav-link-ref" [routerLink]="['/news/9']" (click)="closeMenu()">{{'header.third_menu.fifth_item' | translate }}</a></li>
                    </ul>
                </li>

                <li class="has-submenu">
                    <!-- SERVICIOS -->
                    <a href="javascript:void(0)" (click)="onMenuClick($event)">{{ 'header.fourth_menu.title' |translate }}</a><span class="menu-arrow"></span>
                    <ul class="submenu">
                        <!-- CIENCIA DE DATOS -->
                        <li><a class="nav-link-ref" routerLink="/data-science" (click)="closeMenu()">{{'header.fourth_menu.first_item' |translate }}</a></li>
                        <!-- CONTENIDO DE MARCAS -->
                        <li><a class="nav-link-ref" routerLink="/brand-content" (click)="closeMenu()">{{'header.fourth_menu.second_item' |translate }}</a></li>
                    </ul>
                </li>

                <!-- CONTACTO -->
                <li><a routerLink="contact" class="nav-link-ref" (click)="closeMenu()">{{'header.fifth_menu' | translate }}</a></li>

                <li class="has-submenu" *ngIf="this.session">
                    <!-- PERFIL -->
                    <a href="javascript:void(0)" (click)="onMenuClick($event)">{{ 'header.sixth_menu.title' | translate }}</a><span class="menu-arrow"></span>
                    <ul class="submenu megamenu">
                        <li>
                            <ul>
                                <!-- PERFIL DEL CLIENTE -->
                                <li><a class="nav-link-ref" [routerLink]="['/client/client-profile']" (click)="closeMenu()">{{'header.sixth_menu.first_item' | translate }}</a></li>
                                <!-- EDITAR PERFIL DEL CLIENTE -->
                                <li><a class="nav-link-ref" [routerLink]="['/client/client-edit']" (click)="closeMenu()">{{'header.sixth_menu.second_item' | translate }}</a></li>
                                <!-- PRODUCTOS COMPRADOS -->
                                <li><a class="nav-link-ref" [routerLink]="['/client/purchase-products']" (click)="closeMenu()">{{'header.sixth_menu.third_item' | translate }}</a></li>
                                <!-- CAMBIAR CONTRASEÑA -->
                                <li><a class="nav-link-ref" [routerLink]="['/change-password']" (click)="closeMenu()">{{'header.sixth_menu.fourth_item' | translate }}</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li *ngIf="view === true" (click)="closeMenu()"><a [routerLink]="['cart']" class="nav-link-ref">{{'header.seventh_menu' | translate }}({{shoppingCartItems.length }})</a></li>
                <li *ngIf="this.session && view === true"><a  (click)="logout()" class="nav-link-ref">{{'header.eigth_menu' | translate }}</a></li>
                <li *ngIf="!this.session && view === true"><a [routerLink]="['login']" class="nav-link-ref">{{'header.nineth_menu' | translate }}</a></li>

            </ul>
        </div>
    </div>
</header>