import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from 'src/app/app-config.service';
import { HandleError } from 'src/app/common/handle-error';
import { catchError } from 'rxjs/operators';
import { ProviderPostulation } from '../models/provider-postulation.model';


@Injectable({
  providedIn: 'root'
})
export class ProviderPostulationService {

  private resultPostulation = false;

  constructor(
    private configSrv: AppConfigService,
    private http: HttpClient,
    private handleError: HandleError
  ) { }

    getRows(query?: any, periodId?: number, personId?: number) {
      let url = 'providerPostulation';
      if (periodId !== undefined && personId !== undefined) {
        url = url + '/period/' + periodId + '/person/' + personId;
      }

      let options: any = {};
      if (query) {
        options = {
          params: new HttpParams()
            .set('page', query.pageIndex.toString())
            .set('pageSize', query.pageSize.toString())
            .set('sortActive', query.sortActive)
            .set('sortDirection', query.sortDirection)
            .set('filter', query.filter)
        };
        options.headers = { skip: "true" };
      }
      return this.http.get<any>(this.configSrv.getUrlBasePublic() + url, options).pipe(
        catchError(this.handleError.handleError<any>('getRows'))
      );
    }

    getRow( providerPostId: number) {
      return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'providerPostulation/' +  providerPostId, { headers: { skip: "true" } }).pipe(
        catchError(this.handleError.handleError<any>('getRow'))
      );
    }

    setRow(data: ProviderPostulation) {
      return this.http.post<any>(this.configSrv.getUrlBasePublic() + 'providerPostulation', data, { headers: { skip: "true" } }).pipe(
        catchError(this.handleError.handleError<any>('setRow'))
      );
    }

    putRow(data: ProviderPostulation,  providerPostId: number) {
      return this.http.put<any>(this.configSrv.getUrlBasePublic() + 'providerPostulation/' +  providerPostId, data, { headers: { skip: "true" } }).pipe(
        catchError(this.handleError.handleError<any>('putRow'))
      );
    }

    approve( data: ProviderPostulation, providerPostId: number) {
      return this.http.put<any>(this.configSrv.getUrlBasePublic() + 'providerPostulation/' +  providerPostId + '/approve',data, { headers: { skip: "true" } }).pipe(
        catchError(this.handleError.handleError<any>('approve'))
      );
    }

    reject( data: ProviderPostulation, providerPostId: number) {
      return this.http.put<any>(this.configSrv.getUrlBasePublic() + 'providerPostulation/' +  providerPostId + '/reject',data, { headers: { skip: "true" } }).pipe(
        catchError(this.handleError.handleError<any>('reject'))
      );
    }

    cancel( data: ProviderPostulation, providerPostId: number) {
      return this.http.put<any>(this.configSrv.getUrlBasePublic() + 'providerPostulation/' +  providerPostId + '/cancel',data, { headers: { skip: "true" } }).pipe(
        catchError(this.handleError.handleError<any>('cancel'))
      );
    }

    edit( data: ProviderPostulation, providerPostId: number) {
      return this.http.put<any>(this.configSrv.getUrlBasePublic() + 'providerPostulation/' +  providerPostId + '/edit',data, { headers: { skip: "true" } }).pipe(
        catchError(this.handleError.handleError<any>('edit'))
      );
    }


    set setPostulationResult(result: boolean) {
      this.resultPostulation = result;
    }
  
    get getPostulationResult() {
      return this.resultPostulation;
    }

}
