<div class="back-to-home rounded d-none d-sm-block">
  <a [routerLink]="['../']" class="btn btn-icon btn-soft-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>

<!-- Hero Start -->
<section class="bg-home d-flex align-items-center">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="mr-lg-5">
          <img src="assets/images/user/recovery.svg" class="img-fluid d-block mx-auto" alt="">
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="card login_page shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center">{{ 'recover_password.title' | translate }}</h4>

            <form class="login-form mt-4" [formGroup]="form">
              <div class="row">
                <div class="col-lg-12">
                  <p class="text-muted">{{ 'recover_password.first_item' | translate }}</p>
                  <div class="form-group position-relative">
                    <label>{{ 'recover_password.second_item' | translate }} <span class="text-danger">*</span></label>
                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                    <input type="email" class="form-control pl-5" name="email" formControlName="email" required>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button (click)="sendWithEmail()" class="btn btn-primary btn-block" [disabled]="!form.valid">{{ 'recover_password.button_text' | translate }}</button>
                </div>
                <div class="mx-auto">
                  <p class="mb-0 mt-3"><small class="text-dark mr-2">{{ 'recover_password.third_item' | translate }}</small> <a
                    [routerLink]="['../login']" class="text-dark font-weight-bold" style="cursor: pointer;">{{ 'recover_password.fourth_item' | translate }}</a></p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
