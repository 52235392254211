import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/app-config.service';
import { Rules } from 'src/app/common/rules';
import { StorageService } from 'src/app/common/storage.service';
import Swal from 'sweetalert2';
import { Article } from '../../models/article';
import { ArticleService } from '../../services/article.service';
import { ClientService } from '../../services/client.service';
import { Meta, MetaDefinition } from '@angular/platform-browser';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public form: FormGroup;
  strongPwdRgx = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  hidePassword = true;
  
  subscribe = new Article();
  
  rules = this.rulesSrv.getRegisterRules();

  activeLang = this.sessionSrv.getLanguage();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private clientSrv: ClientService,
    private rulesSrv: Rules,
    private articleSrv: ArticleService,
    private configSrv: AppConfigService,
    private translate: TranslateService,
    private sessionSrv: StorageService,
    private metaService: Meta
  ) {
    this.translate.setDefaultLang(this.activeLang);

    this.form = this.fb.group({
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(5), Validators.pattern(this.strongPwdRgx)]],
      accept_newsletters: [false]
    });
  }

  ngOnInit(): void {
    this.getSubscribe();
  }

  getSubscribe() {
    this.articleSrv.getArticle(this.configSrv.articles_titles.subscribe.article).subscribe(
      resp => {
        if (resp.status) {
          this.subscribe = resp.data;
        }
      }
    );
  }

  goHome() {
    this.router.navigate(['../']);
  }

  goLogin() {
    this.router.navigate(['../login']);
  }

  register() {
    Swal.fire({
      title: '¿Está seguro?',
      // text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, enviar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.form.valid) {
          return Swal.fire('', 'Es necesario completar todos los campos', 'error');
        }
        const data = JSON.parse(JSON.stringify(this.form.value));
        data.password = btoa(data.password.trim());
        
        this.clientSrv.setClient(data).subscribe(resp => {
          if (resp.status) {
            Swal.fire(
              'Bien hecho!',
              'Revisa tu buzón de correo para confirmar tu cuenta',
              'success'
            );
            this.router.navigate(['../login']);
          } else {
            Swal.fire(
              'Error!',
              resp.message,
              'error'
            );
          }
        });


      }
    });
  }

}
