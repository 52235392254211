import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, of } from 'rxjs';
import { StorageService } from 'src/app/common/storage.service';
import { CartItem } from 'src/app/public-pages/models/cart-item';
import { CartService } from 'src/app/public-pages/services/cart.service';
import { TopicService } from 'src/app/public-pages/services/topic.service';
import Swal from 'sweetalert2';
import { createFalse } from 'typescript';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  session = this.sessionSrv.getCurrentSession();

  cart = JSON.parse(localStorage.getItem('cart')) || {};
  client = this.cart?.client || [];

  countries: any[] = [];
  industries: any[] = [];
  productTypes: any[] = [];
  topics: any[] = [];

  public cartItems: Observable<CartItem[]> = of([]);
  public shoppingCartItems: CartItem[] = [];

  @Input() navClass: string;

  

  constructor(
    private router: Router,
    private sessionSrv: StorageService,
    private productSrv: ProductService,
    private cartSrv: CartService,
    private topicSrv: TopicService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
      }
    });

    this.cartItems = this.cartSrv.getItems();
    this.cartItems.subscribe(item => this.shoppingCartItems = item);
  }
  isCondensed = false;
  view = false;
  options = true;

  ngAfterViewInit() {
    this._activateMenuDropdown();
  }

  ngOnInit(): void {
    this.getCountries();
    this.getIndustries();
    this.getProductTypes();
  }

  logout() {
    if (this.shoppingCartItems.length > 0) {
      this.sessionSrv.logout();
      this.router.navigate(['/login']);
    } else {
      this.cartSrv.resetCart();
      this.sessionSrv.logoutAndRemoveCart();
      this.router.navigate(['/login']);
    }

  }

  closeMenu(){
    this.isCondensed = false;
    document.getElementById('navigation').style.display = 'none';
  }

  _activateMenuDropdown() {
    /**
     * Menu activation reset
     */
    const resetParent = (el) => {
      el.classList.remove('active');
      const parent = el.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.remove('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.remove('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.remove('active');
            }
          }
        }
      }
    };
    let links = document.getElementsByClassName('nav-link-ref');
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    for (let i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active');
      const parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add('active');
            }
          }
        }
      }
    }
  }

  /**
   * Window scroll method
   */
  // tslint:disable-next-line: typedef
  windowScroll() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById('topnav').classList.add('nav-sticky');
    } else {
      document.getElementById('topnav').classList.remove('nav-sticky');
    }
    if (document.getElementById('back-to-top')) {
      if (document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100) {
        document.getElementById('back-to-top').style.display = 'inline';
      } else {
        document.getElementById('back-to-top').style.display = 'none';
      }
    }
  }
  /**
   * Toggle menu
   */
  toggleMenu() {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      this.view = true;
      this.options = false;
      document.getElementById('navigation').style.display = 'block';
    } else {
      this.view = false;
      this.options = true;
      document.getElementById('navigation').style.display = 'none';
    }
  }

  /**
   * Menu clicked show the submenu
   */
  onMenuClick(event) {
    event.preventDefault();
    const nextEl = event.target.nextSibling.nextSibling;
    if (nextEl && !nextEl.classList.contains('open')) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove('open');
      }
      nextEl.classList.add('open');
    } else if (nextEl) {
      nextEl.classList.remove('open');
    }
    return false;
  };

  getCountries() {
    this.productSrv.getFilterCountry().subscribe(resp => {
      if (resp.status) {
        this.countries = resp.data;
      }
    });
  }

  getIndustries() {
    this.productSrv.getFilterIndustry().subscribe(resp => {
      if (resp.status) {
        this.industries = resp.data.map((item: any) => {
          item.industry_url = item.industry.replace(' ', '-');
          return item;
        });
      }
    });
  }

  getProductTypes() {
    this.productSrv.getFilterProductType().subscribe(resp => {
      if (resp.status) {
        this.productTypes = resp.data;
      }
    });
  }

}
