<div class="back-to-home rounded">
    <a [routerLink]="['../']" class="btn btn-icon btn-soft-primary">
        <i-feather name="home" class="icons"></i-feather>
    </a>
</div>

<section class="bg-home2 bg-circle-gradiant d-flex align-items-center">
    <div class="bg-overlay bg-overlay-white"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-4">
                <div class="card login_page shadow rounded border-0">
                    <div class="card-body">
                        <h6 class="card-title text-center">{{ 'change_password.title' | translate }}</h6>
                        <form [formGroup]="form">
                            <label>{{ 'change_password.first_item' | translate }} <span class="text-danger">*</span></label>
                            <mat-form-field class="col-md-12" appearance="outline">
                                <input matInput [placeholder]="'change_password.first_subtitle_item' | translate"
                                    [type]="hide ? 'password' : 'text'" formControlName="oldPassword">
                                <mat-icon matSuffix (click)="hide = !hide" style="cursor: pointer;">{{hide ?
                                    'visibility_off' : 'visibility'}}</mat-icon>
                                <mat-error *ngFor="let rule of rules.oldPassword">
                                    <small
                                        *ngIf="form.get('oldPassword').hasError(rule.type) && (form.get('oldPassword').dirty || form.get('oldPassword').touched)">
                                        {{ rule.message }}</small>
                                </mat-error>
                            </mat-form-field>
                            <br />
                            <label>{{ 'change_password.second_item' | translate }} <span class="text-danger">*</span></label>
                            <mat-form-field class="col-md-12" appearance="outline">
                                <input matInput [placeholder]="'change_password.second_subtitle_item' | translate"
                                    [type]="hide2 ? 'password' : 'text'" formControlName="password">
                                <mat-icon matSuffix (click)="hide2 = !hide2" style="cursor: pointer;">{{hide2 ?
                                    'visibility_off' : 'visibility'}}</mat-icon>
                                <mat-error *ngFor="let rule of rules.password">
                                    <small
                                        *ngIf="form.get('password').hasError(rule.type) && (form.get('password').dirty || form.get('password').touched)">
                                        {{ rule.message }}</small>
                                </mat-error>
                            </mat-form-field>
                            <br />

                            <div class="col-lg-12 mb-0">
                                <button class="btn btn-primary btn-block" [disabled]="!form.valid"
                                    (click)="changePassword()">{{ 'change_password.button_text' | translate }}</button>
                            </div>
                        </form>

                        <p style="padding: 16px 8px; color: #8492a6; background-color: #f8f9fc; text-align: center;">
                            © 2020-21 KAN.
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>