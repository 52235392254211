<div class="back-to-home rounded mr-2">
  <a [routerLink]="['../']" class="btn btn-icon btn-soft-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>

<section class="bg-home d-flex align-items-center">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="mr-lg-5">
          <img src="assets/images/user/login.png" class="img-fluid d-block mx-auto">
          <h4 class="card-title text-center" [routerLink]="['../']">{{ 'login.title_image' | translate }}</h4>
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="card login-page bg-white shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center">{{ 'login.title' | translate }}</h4>
            <form class="login-form mt-4" [formGroup]="form">
              <div class="row">

                <mat-form-field class="col-md-12" appearance="outline">
                  <mat-label>{{ 'login.first_item' | translate }}</mat-label>
                  <input matInput formControlName="email">
                  <mat-icon matPrefix>mail </mat-icon>
                  <mat-error *ngFor="let rule of rules.email">
                    <mat-hint
                      *ngIf="form.get('email').hasError(rule.type) && (form.get('email').dirty || form.get('email').touched)">
                      {{ rule.message }}</mat-hint>
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-md-12 mb-3" appearance="outline">
                  <mat-label>{{ 'login.second_item' | translate }}</mat-label>
                  <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password" aria-label="password" aria-describedby="basic-addon1" autocomplete="off">
                  <mat-icon matSuffix style="cursor: pointer;" (click)="hidePassword = !hidePassword">
                    {{ hidePassword ? 'visibility': 'visibility_off' }}
                  </mat-icon>
                  <mat-error *ngFor="let rule of rules.password">
                    <mat-hint
                      *ngIf="form.get('password').hasError(rule.type) && (form.get('password').dirty || form.get('password').touched)">
                      {{ rule.message }}</mat-hint>
                  </mat-error>
                </mat-form-field>

                <div class="col-lg-12">
                  <div class="d-flex justify-content-between mb-3">
                    <p class="forgot-pass mb-0"><a [routerLink]="['../recover-password']"
                        class="text-dark font-weight-bold" style="cursor: pointer;">{{ 'login.third_item' | translate }}</a></p>
                  </div>
                </div>
                <div class="col-lg-12 mb-0">
                  <button class="btn btn-primary btn-block" [disabled]="!form.valid" (click)="login()">{{ 'login.button_text' | translate }}</button>
                </div>

                <div class="col-12 text-center">
                  <p class="mb-0 mt-3"><small class="text-dark mr-2">{{ 'login.fourth_item' | translate }}</small> <a
                      [routerLink]="['../register']" class="text-dark font-weight-bold"
                      style="cursor: pointer;">{{ 'login.fifth_item' | translate }}</a></p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>