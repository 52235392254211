import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { StorageService } from './common/storage.service';
import { PeriodService } from './public-pages/services/period.service';
import { filter, map } from 'rxjs/operators';

export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'KAN - Data Experts';
  subscription: Subscription;

  
  activeLang = this.sessionSrv.getLanguage() ? this.sessionSrv.getLanguage() : 'es';

  constructor(
    public translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private periodSrv: PeriodService,
    private sessionSrv: StorageService,
    private metaService: Meta,
    private titleService: Title,

  ) {

    if(!this.activeLang){
      this.sessionSrv.setLanguage('es');    
    }

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        window['Unicons']['refresh']();
      }
    });

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });

    this.addTag();
  }


  addTag() {
    this.metaService.updateTag({property: "og:title", content: "New title"})
    this.metaService.updateTag({property: 'og:description', content: "Lorem ipsum dolor"})
    this.metaService.updateTag({property: "og:image", content: "https://ws.kandataexperts.com/files/image/2021/Aug/Fri/611ff86883b0c.jpg"})
  }

  ngAfterViewInit(){
    this.translate.setDefaultLang(this.activeLang);
  }

  ngOnInit() {
    
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {
 
        var rt = this.getChild(this.activatedRoute);
        
 
        rt.data.subscribe(data => {
          this.titleService.setTitle(data.title)
 
          // if (data.ogDescription) {
          //   this.metaService.updateTag({ name: 'description', content: data.description })
          // } else {
          //   this.metaService.removeTag("name='description'")
          // }
 
          // if (data.robots) {
          //   this.metaService.updateTag({ name: 'robots', content: data.robots })
          // } else {
          //   this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          // }
 
          if (data.ogUrl) {
            
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url })
          }
 
          if (data.ogTitle) {

            this.metaService.updateTag({property: "og:title", content: data.ogTitle})
            // this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.metaService.removeTag("property='og:title'");
          }
 
          if (data.ogDescription) {

            this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            this.metaService.removeTag("property='og:description'")
          }
 
          if (data.ogImage) {
            this.metaService.updateTag({property: "og:image", content: data.ogImage})
            // this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            this.metaService.removeTag("property='og:image'")
          }
 
 
        })
 
      });


      
    this.periodSrv.getCurrentDate().subscribe(resp => {
      if (resp.status) {
        this.periodSrv.setCurDate = resp.date;
      }
    });


  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }

}
