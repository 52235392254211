import { Component, OnInit, HostListener } from '@angular/core';
import { AppConfigService } from '../../../../app-config.service';

@Component({
  selector: 'app-tap-to-top',
  templateUrl: './tap-to-top.component.html',
  styleUrls: ['./tap-to-top.component.scss']
})
export class TapToTopComponent implements OnInit {

  public showScroll: boolean;
  public showScrollHeight = 500;
  public hideScrollHeight = 10;

  constructor(
    private configSrv: AppConfigService
  ) { }

  ngOnInit() {
  }

  sendWaMessage() {
    const message = encodeURI('¡Hola KAN! Me gustaría mas información.');
    return  this.configSrv.getUrlWa() + message;
  }

}
