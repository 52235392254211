import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { StorageService } from '../common/storage.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        private router: Router,
        private storageSrv: StorageService
    ) {

    }

    canActivate(): boolean {
        if (!this.storageSrv.isAuthenticated()) {
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }

}

