import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'route'
})
export class RoutePipe implements PipeTransform {

  transform(type: string, value: string ): string {
    
    let route = '';
    switch (type) {
      case 'keyword': route = value;
        break;
      case 'top': route = 'productos destacados';
        break;
      case 'country': route = 'pais: ' + value;
        break;
      case 'industry': route = 'industria: ' + value;
        break;
      case 'productType': route = 'tipo de producto: ' + value;
        break;
      default: route = '';
        break;
    }
    return route;
  }

}
