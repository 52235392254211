import { Client } from './client';
import { License } from './license.model';
import { Product } from './product';

export class CartItem{
    product: Product;
    license: License;
    client: Client;
    quantity: number;
    discount: number;

    constructor(product?: Product, license?: License, quantity?: number, discount?: number) {
        this.product = product;
        this.license = license;
        this.quantity = quantity;
        this.discount = discount;
    }
}