import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config.service';
import { HandleError } from 'src/app/common/handle-error';
import { Period } from '../models/period.model';

@Injectable({
  providedIn: 'root'
})
export class PeriodService {
  curDate: Date;
  constructor(
    private http: HttpClient,
    private configSrv: AppConfigService,
    private handleError: HandleError,
  ) { }

  getPeriods(query?: any) {
    let options = {};
    if (query) {
      options = {
        params: new HttpParams()
          .set('page', query.pageIndex.toString())
          .set('pageSize', query.pageSize.toString())
          .set('sortActive', query.sortActive)
          .set('sortDirection', query.sortDirection)
          .set('filter', query.filter)
      };
    }
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'period', options).pipe(
      catchError(this.handleError.handleError<any>('getPeriods'))
    );
  }

  getPeriod(periodId: number) {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'period/' + periodId, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>('getPeriod'))
    );
  }

  setPeriod(data: Period) {
    return this.http.post<any>(this.configSrv.getUrlBasePublic() + 'period', data, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>('setPeriod'))
    );
  }

  putPeriod(data: Period, periodId: number) {
    return this.http.put<any>(this.configSrv.getUrlBasePublic() + 'period/' + periodId, data, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>('putPeriod'))
    );
  }

  getDefaultPeriod() {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'period/default', { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>('putPeriod'))
    );
  }

  getCurrentDate() {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'currentDate', { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>('getCurrentDate'))
    );
  }

  set setCurDate(date: Date) {
    this.curDate = new Date(date);
  }

  get getCurDate() {
    return this.curDate;
  }
}
