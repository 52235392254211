import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { FeatherModule } from 'angular-feather';

import { allIcons } from 'angular-feather/icons';
import { SwitcherComponent } from './shared/switcher/switcher.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ImagePipe } from './shared/pipes/image.pipe';
import { TruncatePipe } from './shared/pipes/truncate.pipe';
import { AuthInterceptor } from './common/authInterceptor';
import { AuthGuardService } from './public-pages/auth.guard.service';
import { RoutePipe } from './shared/pipes/route.pipe';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { SafePipe } from './shared/pipes/safe.pipe';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons'
import { ShareIconsModule } from 'ngx-sharebuttons/icons'
import { TraductorPipe } from './shared/pipes/traductor.pipe';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    ImagePipe,
    TruncatePipe,
    RoutePipe,
    SafePipe,
    TraductorPipe
  ],
  exports: [
    ImagePipe,
    TruncatePipe,
    RoutePipe,
    SafePipe,
    TraductorPipe
  ],
  providers: [
    ImagePipe,
    TruncatePipe,
    RoutePipe,
    SafePipe,
    TraductorPipe
  ]
})
export class PipesModule {}

@NgModule({
  declarations: [
    AppComponent,
    SwitcherComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    CarouselModule,
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    RouterModule.forRoot(routes, { useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'}),
    NgbModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    CommonModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    RecaptchaV3Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    }),
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
  ],
  exports: [
    FeatherModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    Meta,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // },
    { 
      provide: RECAPTCHA_V3_SITE_KEY, 
      useValue: "6LeZhJUbAAAAAChI2mcb_Lo8xWgdnPoeDfj8FDZo" 
    },
    AuthGuardService,
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
