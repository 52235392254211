import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class TraductorService {
  language: 'es';
  constructor(
  ) { }

  set setLanguage(date: any) {
    this.language = date;
  }

  get getLanguage() {
    return this.language;
  }
}
