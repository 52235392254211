import { Pipe, PipeTransform } from '@angular/core';
import { StorageService } from 'src/app/common/storage.service';

@Pipe({
    name: 'traductor'
})
export class TraductorPipe implements PipeTransform {

    language = this.sessionSrv.getLanguage() ? this.sessionSrv.getLanguage() : 'es';

    constructor(
        private sessionSrv: StorageService
    ) {
    }

    transform(value: any, type: any) {
        if (this.language === 'es') {
            if (type === 'title') {
                return value.title;
            } else if (type === 'description') {
                return value.description;
            } else if (type === 'name') {
                return value.name;
            } else if (type === 'short_description') {
                return value.short_description;
            } else if (type === 'periodicity') {
                return value.periodicity;
            } else if (type === 'topic') {
                return value.topic;
            } else if (type === 'topic_description') {
                return value.topic_description;
            } else if (type === 'terms') {
                return value.terms;
            } else if (type === 'industry') {
                return value.industry;
            } else if (type === 'product_type') {
                return value.product_type;
            } else if (type === 'target_market') {
                return value.target_market;
            } else if (type === 'country') {
                return value.country;
            } else if (type === 'topic') {
                return value.topic;
            }
        } else {
            if (type === 'title') {
                return value.title_en;
            } else if (type === 'description') {
                return value.description_en;
            } else if (type === 'name') {
                return value.name_en;
            } else if (type === 'short_description') {
                return value.short_description_en;
            } else if (type === 'periodicity') {
                return value.periodicity_en;
            } else if (type === 'topic') {
                return value.topic_en;
            } else if (type === 'topic_description') {
                return value.topic_description_en;
            } else if (type === 'terms') {
                return value.terms_en;
            } else if (type === 'industry') {
                return value.industry_en;
            } else if (type === 'product_type') {
                return value.product_type_en;
            } else if (type === 'target_market') {
                return value.target_market_en;
            } else if (type === 'country') {
                return value.country_en;
            } else if (type === 'topic') {
                return value.topic_en;
            }
        }
    }
}
