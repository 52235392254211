export class Article {
    article_id?: number;
    title?: string;
    description?: string;
    featured?: number;
    image?: string;
    image_thumb?: string;
    file?: string;
    icon?: string;
    comment?: string;
    reference?: string;
    article_type?: string;
    article_type_id?: number;
    status_id?: number;
    created_at?: Date;
    created_by?: number;
    updated_at?: Date;
    updated_by?: number;
    is_editor?: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
