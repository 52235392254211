import {Injectable} from "@angular/core";
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
  })
export class StorageService {

  cart = JSON.parse(localStorage.getItem('cart')) || {};

  private localStorageService: any;
  private currentSession: any = null;
  private currentCart: any = null;

  constructor(
    private router: Router,
    private translate: TranslateService
  ) {
    this.localStorageService = localStorage;
  }

  setCurrentSession(data: any): void {
    this.localStorageService.setItem('currentUser', JSON.stringify(data));
  }

  loadSessionData(): any {
    const sessionStr = this.localStorageService.getItem('currentUser');
    this.currentSession = (sessionStr) ? <any> JSON.parse(sessionStr) : null;
    return this.currentSession;
  }

  getCurrentSession(): any {
    return this.loadSessionData();
  }

  loadCartData(): any {
    const sessionStr = this.localStorageService.getItem('cart');
    this.currentCart = (sessionStr) ? <any> JSON.parse(sessionStr) : null;
    return this.currentCart;
  }

  getCurrentCart(): any {
    return this.loadCartData();
  }

  removeCurrentSession(): void {
    this.localStorageService.removeItem('currentUser');
    this.currentSession = null;
  }

  getCurrentUser(): any {
    const session: any = this.getCurrentSession();
    return (session && session.user_id) ? session.user_id : null;
  }

  getRol():number {
     return this.getCurrentSession().role_id;
  }

  isAuthenticated(): boolean {
    return ( this.getCurrentToken() != null) ? true : false;
  }

  getCurrentToken(): string {
    const session = this.getCurrentSession();
    return (session && session.api_token) ? session.api_token : null;
  }

  logout(): void {
    this.removeCurrentSession();
  }

  logoutAndRemoveCart(): void {
    this.localStorageService.removeItem('cart');
    this.localStorageService.removeItem('cartItem');
    this.removeCurrentSession();
  }

  deleteCart(): void {
    this.localStorageService.removeItem('cart');
    this.localStorageService.removeItem('cartItem');
  }

  ///PARA POSTULATION

  loadSessionDataPeriod(): any {
    const sessionStr = this.localStorageService.getItem('currentPeriod');
    this.currentSession = (sessionStr) ? <any> JSON.parse(sessionStr) : null;
    return this.currentSession;
  }

  getCurrentSessionPeriod(): any {
    return this.loadSessionDataPeriod();
  }

  //PARA CAMBIO DE LENGUAJE.

  setLanguage(data: any): void {
    this.localStorageService.setItem('currentLanguage', JSON.stringify(data));
    
  }

  loadLanguage(): any {
    const sessionStr = this.localStorageService.getItem('currentLanguage');
    this.currentSession = (sessionStr) ? <any> JSON.parse(sessionStr) : null;
    return this.currentSession;
  }

  getLanguage(): any {
    return this.loadLanguage();
  }

  changeLanguage(language: any){
    this.translate.use(language);
  }

}
