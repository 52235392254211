import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Rules {

    // constructor() {}

    getPostulationRules() {
      return {
        first_name: [
          { type: 'required', message: 'Nombres es requerido.'},
          { type: 'minlength', message: 'Debe tener al menos 3 carácteres' },
        ],
        last_name: [
          { type: 'required', message: 'Apellidos es requerido.'},
          { type: 'minlength', message: 'Debe tener al menos 3 carácteres' },
        ],
        birthday: [
          { type: 'required', message: 'Fecha de nacimiento es requerido.'},
        ],
        document_id: [
          { type: 'required', message: 'Documento es requerido.'},
        ],
        document_number: [
          { type: 'required', message: 'Número de documento es requerido.'},
          { type: 'minlength', message: 'Debe tener al menos 13 carácteres' },
          { type: 'maxlength', message: 'Debe tener maximo 13 carácteres' },
        ],
        main_phone: [
          { type: 'required', message: 'El número celular es requerido' },
        ],
        email: [
          { type: 'required', message: 'Correo es requerido.'},
          { type: 'email', message: 'Correo inválido' },
        ],
        experience_year: [
          { type: 'required', message: 'Los años de experiencia en datos es requerida.'},
        ],
        experience_description: [
          { type: 'required', message: 'La experiencia en datos es requerida.'},
          { type: 'minlength', message: 'La experiencia debe tener al menos 10 carácteres' },
        ],
        comment: [
          { type: 'required', message: 'Las observaciones son requeridas.'},
        ],
        photo: [
          { type: 'required', message: 'La fotografía es requerida.'},
        ],
        identification_file: [
          { type: 'required', message: 'El documento de Identificacion es requerido.'},
        ],
        resume_file: [
          { type: 'required', message: 'El Curriculum es requerido.'},
        ],

      };
    }

    getCreditCardRules() {
      return {
        numberTarjet: [
          { type: 'required', message: 'Número de tarjeta es requerido.'},
          { type: 'minlength', message: 'Debe tener al menos 16 carácteres' },
          { type: 'maxlength', message: 'Debe tener maximo 16 carácteres' },
        ],
        cvv: [
          { type: 'required', message: 'cvv es requerido.'},
          { type: 'minlength', message: 'Debe tener al menos 3 carácteres' },
          { type: 'maxlength', message: 'Debe tener maximo 3 carácteres' },
        ],
        dateExpired: [
          { type: 'required', message: 'La Fecha de expiración es requerida.'},
        ],
        nameProperty: [
          { type: 'required', message: 'El nombre del propietario es requerido.'},
        ],
        payment_method_id: [
          { type: 'required', message: 'El metodo de pago es requerido.'},
        ],
      };
    }

    getRegisterRules() {
      return {
        first_name: [
          { type: 'required', message: 'Nombres es requerido.'},
          { type: 'minlength', message: 'Debe tener al menos 3 carácteres' },
        ],
        last_name: [
          { type: 'required', message: 'Apellidos es requerido.'},
          { type: 'minlength', message: 'Debe tener al menos 3 carácteres' },
        ],
        email: [
          { type: 'required', message: 'Correo es requerido.'},
          { type: 'email', message: 'Correo inválido' },
        ],
        password: [
          { type: 'required', message: 'La contraseña es requerida' },
          { type: 'pattern', message: 'Debe tener letras mayúsculas y minúsculas, al menos un símbolo (@#$%/_*-) y números'},
          { type: 'minlength', message: 'Debe contener al menos 5 carácteres' },
        ],
      };
    }

    getLoginRules() {
      return {
        email: [
          { type: 'required', message: 'Correo es requerido.'},
          { type: 'email', message: 'Correo inválido' },
        ],
        password: [
          { type: 'required', message: 'La contraseña es requerida' },
          { type: 'pattern', message: 'Debe tener letras mayúsculas y minúsculas, al menos un símbolo (@#$%/_*-) y números'},
          { type: 'minlength', message: 'Debe contener al menos 5 carácteres' },
        ],
      };
    }

    getChangePasswordRules() {
      return {
        email: [
          { type: 'required', message: 'Correo es requerido.'},
          { type: 'email', message: 'Correo inválido' },
        ],
        oldPassword: [
          { type: 'required', message: 'La contraseña es requerida' },
          { type: 'pattern', message: 'Debe tener letras mayúsculas y minúsculas, al menos un símbolo (@#$%/_*-) y números'},
          { type: 'minlength', message: 'Debe contener al menos 5 carácteres' },
        ],
        password: [
          { type: 'required', message: 'La contraseña es requerida' },
          { type: 'pattern', message: 'Debe tener letras mayúsculas y minúsculas, al menos un símbolo (@#$%/_*-) y números'},
          { type: 'minlength', message: 'Debe contener al menos 5 carácteres' },
        ],
      };
    }

}
