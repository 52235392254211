import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Product } from '../models/product';
import { CartItem } from '../models/cart-item';
import { Cart } from '../models/cart';
import { AppConfigService } from '../../app-config.service';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HandleError } from '../../common/handle-error'; 
import Swal from 'sweetalert2';
import { License } from '../models/license.model';
import { BehaviorSubject, Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { Client } from '../models/client';
import { ToastrService } from 'ngx-toastr';
import { Payment } from '../models/payment';
import { isPlatformBrowser } from '@angular/common';

let products = JSON.parse(localStorage.getItem('cartItem')) || [];


@Injectable({
  providedIn: 'root'
})
export class CartService {

  cartItems: BehaviorSubject<CartItem[]> = new BehaviorSubject([]);
  showCartItems = false;
  private route = '/';

  constructor(
    private configSrv: AppConfigService,
    private http: HttpClient,
    private handleError: HandleError,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId
    ) {
      
    this.cartItems.subscribe(products => products = products);
  }

  public addToCart(product: Product, license: License, quantity: number): CartItem | boolean {
    let cartItem: CartItem | boolean = false;
    
    const hasAdded = products.find((item: CartItem, index: number) => {
      if (item.product.product_id === product.product_id && item.license.license_id === license.license_id) {
        const qty = item.quantity += quantity;
        if (qty > 0) {
          item.quantity = qty;
          this.toastr.success('Producto actualizado', 'Bien hecho!');
        }
        return true;
      }
    });
    
    
    if (!hasAdded) {
      cartItem = new CartItem(product, license, quantity)
      products.push(cartItem);
      this.toastr.success('Producto agregado', 'Bien hecho!');
    }

    products = products;
    if(isPlatformBrowser(this.platformId)){
      localStorage.setItem('cartItem', JSON.stringify(products));
    }
    return cartItem;
  }

  public removeFromCart(item: CartItem) {
    if (item === undefined) return false;
    const index = products.indexOf(item);
    products.splice(index, 1);
    
    this.toastr.info('Producto eliminado', 'Oops!');

    products = products;
    if(isPlatformBrowser(this.platformId)){
      localStorage.setItem('cartItem', JSON.stringify(products));
    }
  }

  public updateCartQuantity(product: Product, license: License, quantity: number): CartItem | boolean {
    products.find((item: any) => {
      if (item.product.product_id === product.product_id && item.license.license_id === license.license_id) {
        const qty = item.quantity += quantity;
        item.quantity = qty > 0 ? qty : 1;
        this.toastr.success('Producto actualizado', 'Bien hecho!');
        return true;
      }
    });
    products = products;
    if(isPlatformBrowser(this.platformId)){
      localStorage.setItem('cartItem', JSON.stringify(products));
    }
    return true;
  }

  public getItems(): Observable<CartItem[]> {
    const itemsStream = new Observable(observer => {
      observer.next(products);
      observer.complete();
    });
    return <Observable<CartItem[]>>itemsStream;
  }

  public getTotalAmount(): Observable<number> {
    return this.cartItems.map((product: CartItem[]) => {
      return products.reduce((prev, curr: CartItem) => {
        return prev + curr.license.sale_price * curr.quantity;
      }, 0);

    });
  }

  public getPaymentMethods(){
    return this.http.get<any>(this.configSrv.getUrlBasePublic()+ 'paymentMethodCategory', {headers:{skip:"true"}}).pipe(
      catchError(this.handleError.handleError<any>(`getPaymentMethods`))
    );
  }

  public getPaymentMethod(categoryId: number){
    return this.http.get<any>(this.configSrv.getUrlBasePublic()+ 'paymentMethodCategory/category/' + categoryId, {headers:{skip:"true"}}).pipe(
      catchError(this.handleError.handleError<any>(`getPaymentMethods`))
    );
  }

  public resetCart() {
    products.splice(0, products.length);
    if(isPlatformBrowser(this.platformId)){
      localStorage.setItem('cartItem', JSON.stringify(products));
    }
  }

  upsert(data: Cart) {
    return this.http.put<any>(this.configSrv.getUrlBasePrivate() + 'cart/upsert', data).pipe(
      catchError(this.handleError.handleError<any>('upsert'))
    );
  }

  confirmCart(data: any) {
    return this.http.put<any>(this.configSrv.getUrlBasePrivate() + 'cart/confirm', data).pipe(
      catchError(this.handleError.handleError<any>('confirmCart'))
    );
  }

  set setCurRoute(route: string) {
    this.route = route;
  }

  get getCurRoute() {
    return this.route;
  }

  

}
