import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.css']
})
export class ValidateEmailComponent implements OnInit {

  cliente: any;

  constructor(
    private router: Router,
    private clientSrv: ClientService
    ) {
    this.cliente = this.router.parseUrl(this.router.url);
   }

  ngOnInit(): void {
  }

  emailConfirmation() {
    this.clientSrv.emailConfirmation(this.cliente.queryParams).subscribe(resp => {
      if (resp.status) {
        Swal.fire('¡Proceso exitoso!', `${resp.message}`, 'success');
        this.router.navigate(['../login']);
      } else {
        Swal.fire('¡Algo a fallado!', `${resp.message}`, 'error');
      }
    });
  }

}
