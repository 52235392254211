import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config.service';
import { HandleError } from 'src/app/common/handle-error';



@Injectable({
  providedIn: 'root'
})
export class IpInfoService {

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private handleError: HandleError,
    ) { }

  getIpInfo() {
    let options = {
      params: new HttpParams()
        .set('token', this.appConfig.getIpInfoToken())
    };;
    return this.http.get<any>(this.appConfig.getUrlIpInfo() + 'json', options).pipe(
      catchError(this.handleError.handleError<any>('getIpInfo'))
    );
  }

}
