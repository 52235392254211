import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config.service';
import { HandleError } from 'src/app/common/handle-error';
import { StorageService } from 'src/app/common/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private configSrv: AppConfigService,
    private handleError: HandleError,
    private sessionSrv: StorageService,
    private router: Router
  ) { }

  public login(data: any) {
    return this.http.post<any>(this.configSrv.getUrlBasePublic() + 'client/signin', data, {headers:{skip:"true"}}).pipe(
      catchError(this.handleError.handleError(`login`))
    );
  } 

  public changePassword(data: any){
    return this.http.put<any>(this.configSrv.getUrlBasePrivate() + 'client/changePassword', data).pipe(
      catchError(this.handleError.handleError<any>(`changePassword`)));
  }

  public restorePassword(data: any){
    return this.http.post<any>(this.configSrv.getUrlBasePublic() + 'client/restorePassword', data, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`restorePassword`)));
  }

  logout() {
    this.sessionSrv.logout();
    this.router.navigate(['../login']);
  }
}
