import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/common/storage.service';
import Swal from 'sweetalert2';
import { LoginService } from '../../services/login.service';
import { PeriodService } from '../../services/period.service';
import { CartService } from '../../services/cart.service';
import { Rules } from 'src/app/common/rules';
import { TranslateService } from '@ngx-translate/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { IpInfoService } from '../../services/ip-info.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public form: FormGroup;
  session: any;

  strongPwdRgx = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  hidePassword = true;

  rules = this.rulesSrv.getLoginRules();

  cart = JSON.parse(localStorage.getItem('cart')) || {};
// let products = JSON.parse(localStorage.getItem('cartItem')) || [];
  activeLang = this.sessionSrv.getLanguage();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private loginSrv: LoginService,
    private sessionSrv: StorageService,
    private periodSrv: PeriodService,
    private cartSrv: CartService,
    private rulesSrv: Rules,
    private translate: TranslateService,
    private ipInfoSrv: IpInfoService
    ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(5), Validators.pattern(this.strongPwdRgx)]],
      ip_info: [null]
    });

    this.translate.setDefaultLang(this.activeLang);
   }

  ngOnInit(): void {
    this.loadIp();
  }

  login() {
    if (!this.form.valid) {
      return Swal.fire('', 'Es necesario completar todos los campos', 'error');
    }

    const data = JSON.parse(JSON.stringify(this.form.value));
    data.password = btoa(data.password.trim());

    this.loginSrv.login(data).subscribe(resp => {
      if (resp.status) {
        let login = resp.data;

        this.sessionSrv.setCurrentSession(login);

        // this.cart.client = login;
        // localStorage.setItem('cart', JSON.stringify(this.cart));
        
        this.router.navigate([this.cartSrv.getCurRoute]);
      } else {
        Swal.fire('Oops!', resp.message, 'error');
      }
    });
  }

  loadIp() {
    this.ipInfoSrv.getIpInfo().subscribe(resp => {
      if (resp?.ip) {
        this.form.get('ip_info')?.setValue(resp);
      }
    })
  }

}
