import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config.service';
import { HandleError } from 'src/app/common/handle-error';
import { StorageService } from 'src/app/common/storage.service';
import { Product } from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public currency: string = 'USD';
  public catalogMode: boolean = false;

  filterBar = false;
  showSideBar: Subject<boolean> = new Subject<boolean>();

  private productsSource = new BehaviorSubject<Product[]>([]);
  currentProducts = this.productsSource.asObservable();

  activeLang = this.sessionSrv.getLanguage();

  constructor(
    private configSrv: AppConfigService,
    private http: HttpClient,
    private handleError: HandleError,
    private sessionSrv: StorageService
  ) { }


  getProductsByKeyword(query?: any) {
    let options: any = {};
    options.headers = { skip: "true" };

    if (query) {
      options.params = new HttpParams()
      .set('page', query.pageIndex.toString())
      .set('pageSize', query.pageSize.toString())
      .set('sortActive', query.sortActive)
      .set('sortDirection', query.sortDirection)
      .set('filter', query.filter);
    }

    return this.http.get<Product>(this.configSrv.getUrlBasePublic() + 'product/search/language/' + this.activeLang, options ).pipe(
      catchError(this.handleError.handleError<any>('getProductsByKeyword'))
    );
  }

  getFeaturedProducts(query?: any, limit?: number) {
    let url = 'product/featured';
    if (limit) {
      url = url + '/limit/' + limit + '/language/' + this.activeLang;
    }
    let options: any = {};
    options.headers = { skip: "true" };
    
    if (query) {
      options.params = new HttpParams()
      .set('page', query.pageIndex.toString())
      .set('pageSize', query.pageSize.toString())
      .set('sortActive', query.sortActive)
      .set('sortDirection', query.sortDirection)
      .set('filter', query.filter);
    }
    
    return this.http.get<Product>(this.configSrv.getUrlBasePublic() + url, options ).pipe(
      catchError(this.handleError.handleError<any>('getFeaturedProducts'))
    );
  }

  getFeaturedProductsTwo(limit: number) {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/featured/limit/' + limit + '/language/' + this.activeLang, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getFeaturedProductsTwo`))
    );
  }

  getProductsByCountry(query?: any, countryId?: number) {
    let options: any = {};

    if (query) {
      options = {
        params: new HttpParams()
          .set('page', query.pageIndex.toString())
          .set('pageSize', query.pageSize.toString())
          .set('sortActive', query.sortActive)
          .set('sortDirection', query.sortDirection)
          .set('filter', query.filter)
      };
      options.headers = { skip: "true" };
    }
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/showByCountry/' + countryId + '/language/' + this.activeLang, options).pipe(
      catchError(this.handleError.handleError<any>('getProductsByCountry'))
    );
  }

  getProductsByIndustry(query?: any, industryId?: number) {
    let options: any = {};
    
    if (query) {
      options = {
        params: new HttpParams()
          .set('page', query.pageIndex.toString())
          .set('pageSize', query.pageSize.toString())
          .set('sortActive', query.sortActive)
          .set('sortDirection', query.sortDirection)
          .set('filter', query.filter)
      };
      options.headers = { skip: "true" };
    }
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/showByIndustry/' + industryId + '/language/' + this.activeLang, options).pipe(
      catchError(this.handleError.handleError<any>('getProductsByProductType'))
    );
  }

  getProductsByProductType(query?: any, productTypeId?: number) {
    let options: any = {};
    
    if (query) {
      options = {
        params: new HttpParams()
          .set('page', query.pageIndex.toString())
          .set('pageSize', query.pageSize.toString())
          .set('sortActive', query.sortActive)
          .set('sortDirection', query.sortDirection)
          .set('filter', query.filter)
      };
      options.headers = { skip: "true" };
    }
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/showByProductType/' + productTypeId + '/language/' + this.activeLang, options).pipe(
      catchError(this.handleError.handleError<any>('getProductsByProductType'))
    );
  }

  //MOVER A PROVIDERS

  getProductsByProvider(query?: any, providerCode?: number) {
    let options: any = {};
    
    if (query) {
      options = {
        params: new HttpParams()
          .set('page', query.pageIndex.toString())
          .set('pageSize', query.pageSize.toString())
          .set('sortActive', query.sortActive)
          .set('sortDirection', query.sortDirection)
          .set('filter', query.filter)
      };
      options.headers = { skip: "true" };
    }
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'provider/' + providerCode + '/products', options).pipe(
      catchError(this.handleError.handleError<any>('getProductsByProvider'))
    );
  }

  public getProducts(limit = 0) {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/limit/' + limit, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getProducts`))
    );
  }

  public getProduct(productCode: string) {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/' + productCode + '/show', { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getProduct`))
    );
  }

  

  public getDiscountProducts(limit = 0) {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/discount/limit/' + limit, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getDiscountProducts`))
    );
  }

  public getNewestProducts(limit) {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/newest/limit/' + limit, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getNewestProducts`))
    );
  }

  public getProductsByCategory(categoryId: number) {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/category/' + categoryId, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getProductsByCategory`))
    );
  }

  public getProductsByBrand(brandId: number) {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/brand/' + brandId, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getProductsByBrand`))
    );
  }

  // getProductsByKeyword(keywords: string) {
  //   return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/keywords/' + keywords + '/search', { headers: { skip: "true" } }).pipe(
  //     catchError(this.handleError.handleError<any>(`getProductsByKeyword`))
  //   );
  // }

  public getProductsByFilter(filterId: number) {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/filter/' + filterId, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getProductsByFilter`))
    );
  }

  public getCatsWithProducts() {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'productcategory/categories', { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getCatsWithProducts`))
    );
  }

  public getFiltersWithProducts() {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/filters', { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getFiltersWithProducts`))
    );
  }

  public checkDuplicateInObject(tag, Products) {
    var seenDuplicate = false,
      testObject = {};

    Products.map(function (item) {
      var itemPropertyName = item[tag];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      }
      else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  //PRECIOS POR PRODUCTO
  public getProductPrice(productCode: any, licenseTypeId?: number) {

    let url = 'pricesProduct/product/' + productCode;
    if (licenseTypeId !== undefined) {
      url = url + '/licenseType/' + licenseTypeId
    } 
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + url, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getProductPrice`))
    );
  }

  //PRECIOS POR TEMÁTICA
  public getTopicPrice(topicCode: any) {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'pricesTopic/topic/' + topicCode, { headers: { skip: "true" } }).pipe(
      catchError(this.handleError.handleError<any>(`getTopicPrice`))
    );
  }


  sendProducts(products: Product[]) {
    this.productsSource.next(products);
  }

  getFilterTopic() {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/filterTopic', { headers: { skip: "true" } } ).pipe(
      catchError(this.handleError.handleError<any>('getFilterTopic'))
    );
  }

  getFilterIndustry() {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/filterIndustry', { headers: { skip: "true" } } ).pipe(
      catchError(this.handleError.handleError<any>('getFilterIndustry'))
    );
  }
  

  getFilterProductType() {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/filterProductType', { headers: { skip: "true" } } ).pipe(
      catchError(this.handleError.handleError<any>('getFilterProductType'))
    );
  }

  getFilterTarget() {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/filterTargetMarket', { headers: { skip: "true" } } ).pipe(
      catchError(this.handleError.handleError<any>('getFilterTarget'))
    );
  }


  getFilterCountry() {
    return this.http.get<any>(this.configSrv.getUrlBasePublic() + 'product/filterCountry', { headers: { skip: "true" } } ).pipe(
      catchError(this.handleError.handleError<any>('getFilterCountry'))
    );
  }

  // getFile(productFormatId: number) {
  //   return this.http.get<any>(this.configSrv.getUrlBasePrivate() + 'product/' + productFormatId + '/download').pipe(
  //     catchError(this.handleError.handleError<any>('getFile'))
  //   );
  // }

  // getFile(code: any, productFormatId: number) {
  //   return this.http.get(this.configSrv.getUrlBasePrivate() + 'product/' + code + '/format/' + productFormatId + '/download', {responseType: 'blob'}).pipe(
  //     catchError(this.handleError.handleError<any>('getFile'))
  //   );
  // }

  getFile(codeClientPro: any, codeClient: any, productFormatId: number) {
    return this.http.get(this.configSrv.getUrlBasePrivate() + 'download/' + codeClientPro + '/client/' + codeClient + '/productFormat/' + productFormatId, {responseType: 'blob'}).pipe(
      catchError(this.handleError.handleError<any>('getFile'))
    );
  }

}
